import React,{useContext} from 'react';
import { useAppSelector } from "../redux/hooks";
import { ChangePasswordProps } from '../model/Account';
import { validatePassword } from '../redux/auth/authApi';
import logo from "../images/logo.png";
import { MessageContext } from "../context/MesssageContext";

const ChangePasswordDesktop: React.FC<ChangePasswordProps> = ({handleSubmit, params, setParams}) => {
  const context = useContext(MessageContext);
    if (!context) {
        throw new Error('SomeComponent must be used within a MessageProvider');
    }
    const user = useAppSelector((state: any) => state.auth);
    const { errorContent, setErrorContent } = context;
    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      const { name, value } = e.target;
      setParams((prev) => ({ ...prev, [name]: value }));
    };

  const checkField = (event: React.ChangeEvent<HTMLInputElement> | React.KeyboardEvent<HTMLInputElement>) => {
    const { name, value } = event.target as HTMLInputElement;
    let error = '';
    if(value.trim()!==""){
      switch(name){
        case 'NewPassword':{
          error = validatePassword(value, user.userData?.LoginId);
          if(error.trim()!==""){
           setErrorContent(error);
          }
          else{
            setErrorContent('');
          }
        } break;
        case 'ConfirmPassword':{
          if (value !== params.NewPassword) {
            let error = 'Passwords do not match'; 
            setErrorContent(error);
          } else {
            setErrorContent('');
          }
        } break;
      }
    }
  };

  const handleForm = ()=>{
    handleSubmit(params); 
    setParams({...params,errorMessage:''});
  }
    return (
      <div className='ChangePasswordDesktop'>
         <div className="box1out">
        <div className="box1">
         <div className="row mb-30">
           <div className="col-md-6" id="notes">
             <ul className="note">
               <li>Password must have 4 to 15 alphanumeric without white space</li>
               <li>Password cannot be the same as username/nickname</li>
               <li>
                 Password must not contain any special characters (!,@,#,etc...)
               </li>
             </ul>
           </div>
           <div className="col-md-6">
             <form className="login">
               <p id="heading">Change Password</p>
               <div className="inputData">
                 <input name="NewPassword"
                    onBlur={checkField}
                    onChange={handleInputChange} 
                    value={params.NewPassword}
                    id="newPass"
                    type="password"
                    placeholder=" New Password"
                  /> 
                 <input name="ConfirmPassword"
                  onBlur={checkField}
                  onChange={handleInputChange}
                  value={params.ConfirmPassword}
                  id="confirmPass"
                  type="password"
                  placeholder=" New Password Confirm"
                 />
                 <input name="OldPassword"
                  onChange={handleInputChange}
                  value={params.OldPassword}
                  id="currentPass"
                  type="password"
                  placeholder=" Old Password"
                 />
                 <button  id="submit" onClick={(e)=>{e.preventDefault(); handleForm()}} className="btn"><b>Change</b></button>
                 <div id="passerror" style={{color:'red',fontSize:'small'}}>{errorContent}</div>
               </div>
             </form>
           </div>
         </div>
        </div>
        <footer>
         <img src={logo} />
       </footer>
       </div>
      </div>
    );
}
export default ChangePasswordDesktop;